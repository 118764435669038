/* eslint-disable indent */
import React, { useState } from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/Calculator.module.styl'
import GoBackButton from './GoBackButton'

import Slider from '@fil1pe/react-slider'

import { graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/siteApi'
import { useMediaQuery } from 'react-responsive'

function limitFieldLength(value: number, f: (value: number) => void) {
  if (!value) f(0)
  else if (value <= 9999) f(value)
}

export default function Calculator() {
  const { result: formats } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allFormats {
            nodes {
              height
              name
              slug
              ceramic
              value_ceramic
              grout
              value_grout
              mortar
              value_mortar
              width
            }
          }
        }
      `).allFormats.nodes,
    },
    'formats'
  )

  const [activeSize, setActiveSize] = useState(0)
  const [sizeHeightValue, setSizeHeightValue] = useState(formats[0].height)
  const [sizeWidthValue, setSizeWidthValue] = useState(formats[0].width)
  const [ceramicValue, setCeramicValue] = useState(formats[0].value_ceramic)
  const [mortarValue, setMortarValue] = useState(formats[0].value_mortar)
  const [groutValue, setGroutValue] = useState(formats[0].value_grout)
  const heightFormat = sizeHeightValue * 10
  const widthFormat = sizeWidthValue * 10
  const total = heightFormat + widthFormat
  const totalMultiply = heightFormat * widthFormat
  // const [totalEstimado, setTotalEstimado] = useState(0)

  const [rejuntamento, setRejuntamento] = useState(0)
  const [aplicacao, setAplicacao] = useState(0)
  const [junta, setJunta] = useState(3)
  const [ceramica, setCeramica] = useState(formats[0].value_ceramic)
  const [argamassa, setArgamassa] = useState(formats[0].value_mortar)
  const [rejunte, setRejunte] = useState(formats[0].value_grout)
  const [modalActive, setModalActive] = useState(false)
  const isDesktop = useMediaQuery({ query: '(min-width: 1081px)' })

  const valorArgamassa = aplicacao * 7.5
  const valorCeramica = aplicacao + aplicacao * (15 / 100)
  let valorRejunte = 0
  if (
    (sizeWidthValue == 30 && sizeHeightValue == 60) ||
    (sizeWidthValue == 60 && sizeHeightValue == 60)
  ) {
    valorRejunte =
      ((total * 7.4 * junta * rejuntamento) / totalMultiply) * aplicacao
  }

  if (
    (sizeWidthValue == 59 && sizeHeightValue == 59) ||
    (sizeWidthValue == 15 && sizeHeightValue == 90) ||
    (sizeWidthValue == 45 && sizeHeightValue == 90) ||
    (sizeWidthValue == 80 && sizeHeightValue == 80)
  ) {
    valorRejunte =
      ((total * 10 * junta * rejuntamento) / totalMultiply) * aplicacao
  }

  const totalEstimado =
    // eslint-disable-next-line prettier/prettier
    ceramicValue * valorCeramica + mortarValue * valorArgamassa + groutValue * valorRejunte

  const settings = {
    centerMode: true,
  }

  return (
    <div className={cn(st.calculatorContainer, 'page-width')}>
      <GoBackButton />
      <section className={st.calculatorWrapper}>
        <h1>Calculadora</h1>
        <section className={st.formatSlider}>
          <h2>Formatos</h2>
          <Slider
            slidesToShow={!isDesktop ? 3 : 6}
            className={st.formatCarousel}
            finite={true}
            renderArrow={(props) => <button {...props}></button>}
            {...settings}
          >
            {formats.map((item, index) => (
              <div
                key={index}
                className={cn(st.formatItem, {
                  [st.active]: activeSize === index,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveSize(index)
                  setSizeHeightValue(item.height)
                  setSizeWidthValue(item.width)
                  setCeramicValue(item.value_ceramic)
                  setMortarValue(item.value_mortar)
                  setGroutValue(item.value_grout)
                }}
              >
                <div
                  style={{
                    width: !isDesktop
                      ? `${item.width / 2}px`
                      : `${item.width}px`,
                    height: !isDesktop
                      ? `${item.height / 2}px`
                      : `${item.height}px`,
                  }}
                  className={st.formatSizes}
                ></div>
                <p className={cn({ [st.active]: activeSize === index })}>
                  {item.width} <span>x</span> {item.height}
                </p>
              </div>
            ))}
          </Slider>
        </section>
      </section>
      <div className={st.inputsWrapper}>
        <form>
          <fieldset>
            <legend>Área de aplicação</legend>
            <label className={st.aplicacao}>
              <input
                type="number"
                placeholder="0"
                maxLength={4}
                className={st.m2}
                value={String(aplicacao)}
                onChange={(e) =>
                  limitFieldLength(e.target.valueAsNumber, setAplicacao)
                }
              />
            </label>
          </fieldset>
          <fieldset className={st.junta}>
            <legend>Junta</legend>
            <label htmlFor="3mm">
              <input
                type="radio"
                name="3mm"
                value={3}
                checked={junta === 3}
                onChange={() => setJunta(3)}
              />
              3mm
            </label>
            <label htmlFor="2mm">
              <input
                type="radio"
                name="2mm"
                value={2}
                checked={junta === 2}
                onChange={() => setJunta(2)}
              />
              2mm
            </label>
            <label htmlFor="1,5mm">
              <input
                type="radio"
                name="1,5mm"
                value={1.5}
                checked={junta === 1.5}
                onChange={() => setJunta(1.5)}
              />
              1,5mm
            </label>
          </fieldset>
          <fieldset>
            <legend>Tipo de rejuntamento</legend>
            <label className={st.selectLabel}>
              <select
                onChange={(e) => setRejuntamento(Number(e.target.value))}
                name="rejunte"
                id="rejunte"
              >
                <option value="">Escolha o tipo</option>
                <option value={1.58}>Epóxi</option>
                <option value={1.75}>Cimentício/Acrílico</option>
                <option value={1.32}>Rejunte sobre rejunte</option>
              </select>
            </label>
          </fieldset>
        </form>
        <div className={st.values}>
          <p>
            Cerâmica
            <span>
              {valorCeramica
                ? valorCeramica.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}
              <span className={st.unity}>m²</span>
            </span>
          </p>
          <p>
            Argamassa
            <span>
              {valorArgamassa
                ? valorArgamassa.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}
              <span className={st.unity}>kg</span>
            </span>
          </p>
          <p>
            Rejunte
            <span>
              {valorRejunte
                ? valorRejunte.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}
              <span className={st.unity}>kg</span>
            </span>
          </p>
        </div>
      </div>
      <div className={st.total}>
        <button onClick={() => setModalActive(true)}>
          <span className="icon-edit"></span>
        </button>
        <section className={st.upper}>
          <h3>Total Estimado</h3>
          <span>
            R$
            <span className={st.money}>
              {totalEstimado
                ? totalEstimado.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}
            </span>
          </span>
          <div className={st.somatorias}>
            <div>
              <p>
                Cerâmica
                <span>
                  R$
                  {ceramicValue
                    ? (ceramicValue * valorCeramica).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 0}
                </span>
              </p>
              <span>+</span>
              <p>
                Argamassa
                <span>
                  R${' '}
                  {mortarValue
                    ? (mortarValue * valorArgamassa).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 0}
                </span>
              </p>
              <span>+</span>
              <p>
                Rejunte
                <span>
                  R${' '}
                  {groutValue
                    ? (groutValue * valorRejunte).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : 0}
                </span>
              </p>
            </div>
          </div>
        </section>
        <div className={st.lower}>
          <p>
            Cerâmica
            <span>
              R${' '}
              {ceramicValue
                ? ceramicValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}{' '}
              m²
            </span>
          </p>
          <p>
            Argamassa
            <span>
              R${' '}
              {mortarValue
                ? mortarValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}{' '}
              kg
            </span>
          </p>
          <p>
            Rejunte
            <span>
              R${' '}
              {groutValue
                ? groutValue.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : 0}{' '}
              kg
            </span>
          </p>
        </div>
      </div>
      <div className={cn(st.calculatorModal, { [st.active]: modalActive })}>
        <section className={st.modalWrapper}>
          <button
            className={st.closeLogin}
            onClick={() => setModalActive(false)}
          >
            <span className={st.close}></span>
          </button>
          <h2>Calculadora</h2>
          <p>Defina os valores das unidades</p>
          <form>
            <fieldset>
              <legend>Cerâmica</legend>
              <label className={st.ceramica}>
                <input
                  type="number"
                  value={String(ceramicValue)}
                  onChange={(e) =>
                    limitFieldLength(e.target.valueAsNumber, setCeramicValue)
                  }
                />
              </label>
            </fieldset>
            <fieldset>
              <legend>Argamassa</legend>
              <label>
                <input
                  type="number"
                  value={String(mortarValue)}
                  onChange={(e) =>
                    limitFieldLength(e.target.valueAsNumber, setMortarValue)
                  }
                />
              </label>
            </fieldset>
            <fieldset>
              <legend>Rejunte</legend>
              <label>
                <input
                  type="number"
                  value={String(groutValue)}
                  onChange={(e) =>
                    limitFieldLength(e.target.valueAsNumber, setGroutValue)
                  }
                />
              </label>
            </fieldset>
          </form>
          <button className={st.save} onClick={() => setModalActive(false)}>
            Salvar
          </button>
        </section>
      </div>
    </div>
  )
}
