import React from 'react'
import { Head } from '~/components'
import Calculator from '~/components/Calculator'
import Newsletter from '~/components/Newsletter'

const Home = () => (
  <>
    <Head />
    <Calculator />
    <Newsletter />
  </>
)

export default Home
