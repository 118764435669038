// extracted by mini-css-extract-plugin
export var active = "Calculator-module--active--30161";
export var aplicacao = "Calculator-module--aplicacao--d920d";
export var calculatorContainer = "Calculator-module--calculator-container--99fb2";
export var calculatorModal = "Calculator-module--calculator-modal--d7641";
export var calculatorWrapper = "Calculator-module--calculator-wrapper--a3c12";
export var ceramica = "Calculator-module--ceramica--84b34";
export var close = "Calculator-module--close--9be55";
export var closeLogin = "Calculator-module--close-login--31008";
export var dots = "Calculator-module--dots--a667e";
export var formatCarousel = "Calculator-module--format-carousel--29237";
export var formatItem = "Calculator-module--format-item--04895";
export var formatSizes = "Calculator-module--format-sizes--e3915";
export var formatSlider = "Calculator-module--format-slider--094af";
export var grecaptchaBadge = "Calculator-module--grecaptcha-badge--62dd5";
export var hideOnDesktop = "Calculator-module--hide-on-desktop--163d3";
export var hideOnMobile = "Calculator-module--hide-on-mobile--afda1";
export var iconAccountDefault = "Calculator-module--icon-account-default--ae4ba";
export var iconArrow = "Calculator-module--icon-arrow--f1663";
export var iconBrand = "Calculator-module--icon-brand--cdd42";
export var iconBrand1 = "Calculator-module--icon-brand1--8c34e";
export var iconBrand2 = "Calculator-module--icon-brand2--82332";
export var iconCalculator = "Calculator-module--icon-calculator--588bf";
export var iconCamera = "Calculator-module--icon-camera--56049";
export var iconCheckbox = "Calculator-module--icon-checkbox--96d11";
export var iconChevron = "Calculator-module--icon-chevron--23d3d";
export var iconDelete = "Calculator-module--icon-delete--0986b";
export var iconDisclaimer = "Calculator-module--icon-disclaimer--cf81d";
export var iconDone = "Calculator-module--icon-done--74d08";
export var iconDownload = "Calculator-module--icon-download--19618";
export var iconEdit = "Calculator-module--icon-edit--089dc";
export var iconFacebook = "Calculator-module--icon-facebook--89250";
export var iconFaq = "Calculator-module--icon-faq--5dcf8";
export var iconFaqUp = "Calculator-module--icon-faq-up--d3f1a";
export var iconFilledHeart = "Calculator-module--icon-filled-heart--5d8d8";
export var iconFlag = "Calculator-module--icon-flag--d18da";
export var iconHeart = "Calculator-module--icon-heart--e4421";
export var iconInstagram = "Calculator-module--icon-instagram--c41a6";
export var iconLog = "Calculator-module--icon-log--824c0";
export var iconLoginArrow = "Calculator-module--icon-login-arrow--92872";
export var iconLogout = "Calculator-module--icon-logout--772dd";
export var iconPassword = "Calculator-module--icon-password--57c4c";
export var iconPinterestP = "Calculator-module--icon-pinterest-p--84a1d";
export var iconRate15 = "Calculator-module--icon-rate-15--7650f";
export var iconRate25 = "Calculator-module--icon-rate-25--767e7";
export var iconRate35 = "Calculator-module--icon-rate-35--d9551";
export var iconRate45 = "Calculator-module--icon-rate-45--eb374";
export var iconRate55 = "Calculator-module--icon-rate-55--6c38c";
export var iconRating = "Calculator-module--icon-rating--83d82";
export var iconSocial = "Calculator-module--icon-social--24f66";
export var iconSocial1 = "Calculator-module--icon-social1--50dc1";
export var iconSocial2 = "Calculator-module--icon-social2--ea795";
export var iconStores = "Calculator-module--icon-stores--126a2";
export var iconTrophy = "Calculator-module--icon-trophy--cbf54";
export var iconUser = "Calculator-module--icon-user--87847";
export var iconUserCircle = "Calculator-module--icon-user-circle--91ec6";
export var iconYoutube = "Calculator-module--icon-youtube--6353c";
export var inputsWrapper = "Calculator-module--inputs-wrapper--7d819";
export var junta = "Calculator-module--junta--1953d";
export var lower = "Calculator-module--lower--c872d";
export var m2 = "Calculator-module--m2--a0235";
export var modalWrapper = "Calculator-module--modal-wrapper--4352d";
export var money = "Calculator-module--money--504c8";
export var pageWidth = "Calculator-module--page-width--4247f";
export var save = "Calculator-module--save--ffba5";
export var selectLabel = "Calculator-module--select-label--c7f02";
export var somatorias = "Calculator-module--somatorias--18af2";
export var total = "Calculator-module--total--2911e";
export var unity = "Calculator-module--unity--78095";
export var upper = "Calculator-module--upper--b6c8b";
export var values = "Calculator-module--values--8f7a0";
export var view = "Calculator-module--view--c578d";
export var views = "Calculator-module--views--69335";